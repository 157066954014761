import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'

import {
  Grid,
  Paper,
  Avatar,
  Typography,
  CircularProgress
} from '@mui/material'

import { database } from 'config/firebase';

import { IProject } from 'types/project.type';

import { useUserData } from 'hooks/useUserData';

const Projects = () => {

  const navigate = useNavigate()

  const { name } = useUserData()

  const [projects, setProjects] = useState<IProject[] | null>(null)

  async function getProjects() {
    const { docs } = await database.collection('projects').get()

    const data = docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data()
      }
    }) as IProject[]

    setProjects(data)
  }

  useEffect(() => {
    getProjects()
  }, [])

  if (!projects) {
    return <CircularProgress color="primary" />
  }
  return (
    <>
      <Typography variant="h3" m={1} mt={2}>Bem Vindo, {name}!</Typography>
      <Typography variant="h5" m={1} mt={3}>Selecione um projeto</Typography>
      <Grid container>
        {
          projects.map(project => (
            <Grid
              item
              xs={12}
              md={2}
            >
              <Paper
                key={project.id}
                elevation={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  p: 3,
                  m: 1
                }}
                onClick={() => navigate(`/project/${project.id}`)}
              >
                <Avatar
                  alt={`image of project ${project.name}`}
                  src={project.image}
                  sx={{
                    width: 96,
                    height: 96,
                    mb: 2
                  }}
                />
                <Typography variant="body1">{project.name}</Typography>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </>
  )
}

export default Projects