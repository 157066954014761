import React from 'react';

import {
  Typography
} from '@mui/material';

import GoBack from 'components/GoBack';

const Events = () => {
  return (
    <>
      <GoBack />
      <Typography variant="h5">Não temos eventos disponíveis no momento</Typography>
    </>
  )
}

export default Events