/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom'

import {
  Paper,
  Typography,
  CircularProgress,
  Grid,
  Button,
  Box,
  IconButton,
  Modal
} from '@mui/material';

import {
  Add,
  DeleteForever
} from '@mui/icons-material';

import { database, storage, firebase } from 'config/firebase'

import { ISong } from 'types/project.type';

import GoBack from 'components/GoBack';

import { useUserData } from 'hooks/useUserData';

import { isAllowed } from 'utils/permission';
import { styleModal } from 'components/Modal/style';
import { FilePond } from 'react-filepond'
import readFile from 'utils/storage';

const SongsDetails = () => {
  const { role } = useUserData()
  const { projectId } = useParams()

  const [songs, setSongs] = useState<ISong[] | null>(null)
  const [openModalNewAudio, setOpenModalNewAudio] = useState(false)

  const [files, setFiles] = useState([]);
  const ref = useRef(null);

  async function getSongs() {
    await database
      .collection('songs')
      .where('project', '==', projectId)
      .onSnapshot(query => {
        const data = query.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data()
          }
        }) as ISong[]

        setSongs(data)
      })


  }

  async function handleAudio(metadata: firebase.storage.FullMetadata) {
    const pathDownload = await readFile(`/${metadata.fullPath}`)

    const data = {
      name: metadata.name.split('.')[0],
      project: projectId,
      url: pathDownload,
      local: metadata.fullPath
    }

    await database
      .collection('songs')
      .add(data)
  }

  async function handleDeleteAudio(local: string, id: string) {
    if (window.confirm('Voce deseja relamente excluir esse audio ?')) {
      await storage.ref().child(local).delete()
      await database.collection('songs').doc(id).delete()
      const data: any = songs?.filter(s => s.id !== id)
      setSongs(data)
    }
  }

  function closeModal() {
    setOpenModalNewAudio(false)
  }

  useEffect(() => {
    getSongs()
  }, [])

  if (!songs) {
    return <CircularProgress color="primary" />
  }

  if (songs && songs.length === 0) {
    return (
      <>
        <GoBack />
        <Typography variant="h5">Não temos áudios disponíveis no momento</Typography>
      </>
    )
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <GoBack />
        {
          isAllowed(role as string, 'add file') && (
            <Button
              onClick={() => setOpenModalNewAudio(true)}
              variant="outlined"
            >
              <Add />
              Adicionar um novo áudio
            </Button>
          )
        }
      </Box>

      {/* Modal Add song */}
      <Modal
        open={openModalNewAudio}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: '30vw' }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={3}>
            Registre um novo áudio de referença
          </Typography>

          <FilePond
            files={files}
            ref={ref}
            allowMultiple={true}
            maxFiles={10}
            name="files"
            labelIdle="Arraste quantos áudios quiser ou clique para pesquisar!"
            acceptedFileTypes={["audio/mpeg"]}
            server={{
              process: (
                _fieldName,
                file,
                _metadata,
                load,
                error,
                progress,
                _abort
              ) => {
                const audio = storage.ref().child(`songs/${file.name}`).put(file, {
                  contentType: file.type
                });
                audio.on(
                  firebase.storage.TaskState.RUNNING,
                  (snap: any) => {
                    progress(true, snap.bytesTransferred, snap.totalBytes);
                  },
                  (err: any) => {
                    error(err.message);
                  },
                  () => {
                    handleAudio(audio.snapshot.metadata)
                    load(file.name)
                  }
                );
              },
            }}
            onupdatefiles={fileItems => {
              setFiles(fileItems.map(fileItem => fileItem.file) as any)
            }}
          />
        </Box>
      </Modal>

      <Grid container>
        {
          songs.map(({ id, name, url, local }) => (
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                p: 1
              }}
            >
              <Paper
                key={id}
                elevation={1}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 150
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '100%',
                    mb: 2
                  }}
                >
                  <Typography variant="h6">{name}</Typography>
                  <IconButton onClick={() => handleDeleteAudio(local, id)}>
                    <DeleteForever color="error" />
                  </IconButton>
                </Box>

                <audio controls src={url} />
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </div>
  )
}

export default SongsDetails