import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import {
  Typography,
  Grid,
  Box,
  TextField,
  Paper,
  Button
} from '@mui/material'

import { useUserData } from 'hooks/useUserData'
import background from 'assets/background.jpeg'

import { auth, database } from 'config/firebase'

import roles from 'enum/roles'

const Login = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  async function handleLogin() {
    setLoading(true)
    if (!email || !password) {
      toast.error('All fields is required')
      return false
    }

    const authenticated = await auth.signInWithEmailAndPassword(email, password)
    if (authenticated) {
      toast.success('Logado com sucesso!')

      const { docs } = await database
        .collection('roles')
        .where('user', '==', auth.currentUser?.uid)
        .get()

      const [data] = docs.map(doc => doc.data())

      useUserData.setState({
        id: auth.currentUser?.uid,
        email,
        name: email.split('@')[0],
        logged: true,
        role: roles[data.role]
      })

      data && navigate('/projects')

      return
    }

    toast.error('Erro ao fazer o login, tente novamente!')
    setLoading(false)

  }

  function handleLoginAnonymous() {

    useUserData.setState({
      id: '1',
      email: 'anônimo@academiasymphonia.com',
      name: 'anônimo',
      logged: true,
      role: 'ANONYMOUS'
    })

    navigate('/projects')
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        sm={6}
        md={8}
        sx={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1
          }}
        >

          <Typography component="h1" variant="h5">
            Login - Symphonia Drive
          </Typography>

          <TextField
            margin="normal"
            variant="filled"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={email}
            onChange={text => setEmail(text.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            variant="filled"
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={text => setPassword(text.target.value)}
          />

          <Box 
            sx={{
              display: 'flex',
              width: '100%',
              mt: 3, 
              mb: 2
            }}
          >

          <Button
            onClick={handleLoginAnonymous}
            variant="outlined"
            fullWidth
            sx={{ mr: 1 }}
            disabled={loading}
          >
            Acessar Anônimo
          </Button>

          <Button
            onClick={handleLogin}
            variant="contained"
            fullWidth
            sx={{ ml: 1 }}
            disabled={loading}
          >
            Acessar
          </Button>
          </Box>

        </Box>

      </Grid>
    </Grid>
  )
}

export default Login
