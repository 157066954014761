import React from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const GoBack = () => {
  const navigate = useNavigate()
  return (
    <Tooltip title="Voltar para a página anterior">
      <IconButton sx={{ mb: 2 }} color="primary" size="medium" onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>
    </Tooltip>
  )
}

export default GoBack;