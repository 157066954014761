/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'

import {
  Grid,
  Paper,
  Typography,
  IconButton
} from '@mui/material'

import {
  VideoLibrary,
  Audiotrack,
  AudioFile,
  Photo,
  EventAvailable
} from '@mui/icons-material';

import { useUserData } from 'hooks/useUserData';


import GoBack from 'components/GoBack';

const menuItems = [
  {
    name: 'Vídeos dos ensaios',
    path: 'rehearsals',
    icon: <VideoLibrary />,
    permissions: [
      'ADMIN',
      'MEMBER',
      'ANONYMOUS'
    ]
  },
  {
    name: 'Áudios de referência',
    path: 'songs',
    icon: <AudioFile />,
    permissions: [
      'ADMIN',
      'MEMBER'
    ]
  },
  {
    name: 'Patiruras',
    path: 'scores',
    icon: <Audiotrack />,
    permissions: [
      'ADMIN'
    ]
  },
  {
    name: 'Imagens dos ensaios',
    path: 'photos',
    icon: <Photo />,
    permissions: [
      'ADMIN',
      'MEMBER',
    ]
  },
  {
    name: 'Eventos',
    path: 'events',
    icon: <EventAvailable />,
    permissions: [
      'ADMIN',
      'MEMBER',
    ]
  },
]

const ProjectDetails = () => {

  const navigate = useNavigate()

  const { role } = useUserData()

  const [currentMenu, setCurrentMenu] = useState<any[]>([])

  function configureMenuByCurrentUser() {
    const menu = menuItems.filter(menuItem => menuItem.permissions.includes(role as string))
    setCurrentMenu(menu)
  }

  useEffect(() => {
    configureMenuByCurrentUser()
  }, [])

  return (
    <>
      <GoBack />
      <Grid container>
        {
          currentMenu.map((project, index) => (
            <Grid
              item
              xs={12}
              md={3}
            >
              <Paper
                key={index}
                elevation={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  p: 3,
                  m: 1
                }}
                onClick={() => navigate(project.path)}
              >
                <IconButton sx={{ color: '#000' }} size="large">
                  {project.icon}
                </IconButton>
                <Typography variant="body1">{project.name}</Typography>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </>
  )
}

export default ProjectDetails