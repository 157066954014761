/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  CircularProgress,
  Grid,
  Box,
  Button,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  FilledInput,
  FormHelperText
} from '@mui/material';

import {
  ExpandMore,
  FolderOpen,
  DeleteForever,
  Edit,
  Add
} from '@mui/icons-material';

import { database } from 'config/firebase'

import { IFolders } from 'types/project.type';

import GoBack from 'components/GoBack';
import { isAllowed } from 'utils/permission';
import { useUserData } from 'hooks/useUserData';
import { styleModal } from 'components/Modal/style';
import moment from 'moment';

const RehearsalDetails = () => {
  const { role } = useUserData()
  const { projectId } = useParams()

  const [folders, setFolders] = useState<IFolders[] | null>(null)
  const [openModalRegisterNewRehearsal, setOpenModalRegisterNewRehearsal] = useState(false)
  const [expanded, setExpanded] = useState<string | false>(false);
  const [rehearsalId, setRehearsalId] = useState<string | null>(null)
  const [dateRehearsal, setDateRehearsal] = useState('')
  const [videos, setVideos] = useState([
    {
      id: 1,
      value: ''
    }
  ])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function getRehearsal() {
    const { docs } = await database
      .collection('rehearsals')
      .where('project', '==', projectId)
      .get()

    const data = docs.map((doc) => {
      const order = moment(doc.data().folder, 'DD/MM/YYYY').toDate()
      return {
        id: doc.id,
        order,
        ...doc.data()
      }
    }) as IFolders[]

    const compare = (a: IFolders, b: IFolders) => {
      if (a.order < b.order) {
        return 1;
      }
      if (a.order > b.order) {
        return -1;
      }
      return 0;
    }

    setFolders(data.sort(compare))
  }

  async function handleAddRehearsal() {
    const data: any = {
      folder: dateRehearsal,
      project: projectId,
      videos: videos.map(video => {
        return video.value
      }).filter(v => v.length > 0)
    }

    if (rehearsalId) {
      await database
        .collection('rehearsals')
        .doc(rehearsalId)
        .update(data)

      getRehearsal()
    } else {
      data.createAt = new Date()
      await database
        .collection('rehearsals')
        .add(data)

      getRehearsal()
    }

    closeModal()
  }

  async function handleEditRehearsal(idRehearsal: string) {
    setRehearsalId(idRehearsal)
    const [data] = folders?.filter(f => f.id === idRehearsal) as any
    setOpenModalRegisterNewRehearsal(true)
    setDateRehearsal(data.folder)

    const dataVideos = data.videos.map((value: string, index: any) => {
      return {
        id: index + 1,
        value
      }
    })

    setVideos(dataVideos)
  }

  async function handleRemoveRehearsal(rehearsalId: string) {
    if (window.confirm('Você tem certeza que deseja excluir esse ensaio ?')) {
      await database
        .collection(`rehearsals`)
        .doc(rehearsalId)
        .delete()

      const data = folders?.filter(folder => folder.id !== rehearsalId)
      setFolders(data as any)
    }
  }

  function addMoreInputVide() {
    const data = {
      id: videos[videos.length - 1].id + 1,
      value: ''
    }

    setVideos([...videos, data])
  }

  function onChangeVideos(videoId: number, value: string) {
    videos[videoId - 1].value = value
    setVideos([...videos])
  }

  function closeModal() {
    setOpenModalRegisterNewRehearsal(false)
    setDateRehearsal('')
    setRehearsalId(null)
    setVideos([
      {
        id: 1,
        value: ''
      }
    ])
  }

  useEffect(() => {
    getRehearsal()
  }, [])

  if (!folders) {
    return <CircularProgress color="primary" />
  }

  if (folders && folders.length === 0) {
    return (
      <>
        <GoBack />
        <Typography variant="h5">Não temos vídeos disponíveis no momento</Typography>
      </>
    )
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <GoBack />
        {
          isAllowed(role as string, 'add rehearsal') && (
            <Button
              onClick={() => setOpenModalRegisterNewRehearsal(true)}
              variant="outlined"
            >
              <Add />
              Adicionar um novo ensaio
            </Button>
          )
        }
      </Box>

      {/* Modal Add and Edit Rehearsal */}
      <Modal
        open={openModalRegisterNewRehearsal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {rehearsalId ? "Editar ensaio" : "Registre um novo ensaio"}
          </Typography>
          <Box
            mt={2}
            display="flex"
            flexDirection="column"
          >
            <TextField
              label="Data do ensaio"
              variant="filled"
              placeholder="24 Jan 2022"
              value={dateRehearsal}
              onChange={text => setDateRehearsal(text.target.value)}
              sx={{
                p: 0.2
              }}
            />
            <Grid container>
              {
                videos.map((video) => (
                  <Grid
                    key={video.id}
                    item
                    xs={12}
                    md={4}
                    sx={{
                      p: 0.2
                    }}
                  >
                    <FormControl margin="dense" variant="filled" fullWidth>
                      <InputLabel htmlFor={`video-${video.id}`}>Video {video.id}</InputLabel>
                      <FilledInput id={`video-${video.id}`} value={video.value} onChange={text => onChangeVideos(video.id, text.target.value)} />
                    </FormControl>
                  </Grid>
                ))
              }
            </Grid>
            <FormHelperText
              onClick={addMoreInputVide}
              sx={{
                textTransform: 'uppercase',
                cursor: 'pointer',
                width: 'fit-content',
                ml: 0.5
              }}
            >
              Add novo video
            </FormHelperText>

            <Button
              onClick={handleAddRehearsal}
              variant="contained"
              sx={{ mt: 4 }}
            >
              {rehearsalId ? "Editar" : "Cadastrar"}
            </Button>
          </Box>

        </Box>
      </Modal>

      {
        folders.map(({ id, folder, videos }) => (
          <Accordion key={id} expanded={expanded === id} onChange={handleChange(id)}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
            >
              <FolderOpen sx={{ mr: 1 }} />
              <Typography>{moment(folder, 'DD/MM/YYYY').locale('pt-br').format('LL')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {
                isAllowed(role as string, 'edit rehearsal') && (
                  <Button
                    onClick={() => handleEditRehearsal(id)}
                    variant="outlined"
                    color="info"
                    sx={{
                      ml: 2,
                      mr: 2
                    }}
                  >
                    <Edit />
                    Editar Ensaio
                  </Button>
                )
              }
              {
                isAllowed(role as string, 'delete rehearsal') && (
                  <Button
                    onClick={() => handleRemoveRehearsal(id)}
                    variant="outlined"
                    color="error"
                  >
                    <DeleteForever />
                    Excluir Ensaio
                  </Button>
                )
              }
              <Grid container component="main">
                {
                  videos.map((video, indexVideo) => (
                    <Grid
                      key={indexVideo}
                      item
                      xs={12}
                      md={12}
                      sx={{
                        p: 1,
                        height: 620
                      }}
                    >
                      <iframe
                        src={video}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                          marginTop: 16,
                          borderRadius: 8,
                          border: 'none',
                          width: '100%',
                          height: '100%'
                        }}
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))
      }
    </div>
  )
}

export default RehearsalDetails