import firebase from 'firebase/compat/app';

import 'firebase/compat/analytics'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'

const firebaseConfig = {
  apiKey: "AIzaSyBHNA4mG3pycdgruFvdfFUwRKRKf9xVBtE",
  authDomain: "symphonia-d915c.firebaseapp.com",
  projectId: "symphonia-d915c",
  storageBucket: "symphonia-d915c.appspot.com",
  messagingSenderId: "932620433534",
  appId: "1:932620433534:web:318f039ee1516146c8402f",
  measurementId: "G-6YDF1KD3MK"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

firebase.analytics()
const database = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

export { firebase, database, auth, storage };