import React from 'react'
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate
} from 'react-router-dom'

import { useUserData } from 'hooks/useUserData'

import Login from 'pages/Common/Login'

import Rehearsal from 'pages/Auth/Rehearsal'
import Songs from 'pages/Auth/Songs'
import Scores from 'pages/Auth/Scores'
import Projects from 'pages/Auth/Projects'
import ProjectDetails from 'pages/Auth/Projects/Details'
import Photos from 'pages/Auth/Photos'
import Events from 'pages/Auth/Events'

import AuthLayout from 'layouts/Auth'

function RequireAuth ({ children }: any) {
  const { logged } = useUserData()
  if (!logged) {
    return <Navigate to="/" />
  }

  return children
}



const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        element={
          <RequireAuth>
            <AuthLayout />
          </RequireAuth>
        }
      >
        <Route path="/projects" element={<Projects />} />
        <Route path="/project/:projectId" element={<ProjectDetails />} />
        <Route path="/project/:projectId/rehearsals" element={<Rehearsal />} />
        <Route path="/project/:projectId/songs" element={<Songs />} />
        <Route path="/project/:projectId/scores" element={<Scores />} />
        <Route path="/project/:projectId/photos" element={<Photos />} />
        <Route path="/project/:projectId/events" element={<Events />} />
        <Route path="*" element={<Projects />} />
      </Route>

      <Route path="*" element={<Login />} />
    </Routes>
  </BrowserRouter>
)

export default Router
