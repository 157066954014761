import create from 'zustand'
import { persist } from 'zustand/middleware'

type User = {
  id?: string,
  name?: string,
  email?: string,
  logged?: boolean,
  role?: string
}

export const useUserData = create<User>(
  persist(
    () => ({ }), { name: 'useUserData' }
  )
)
