/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  CircularProgress,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  ListItemIcon,
  Button,
  Modal
} from '@mui/material';

import {
  FolderOpen,
  PictureAsPdf,
  OpenInNew,
  ExpandMore,
  Add,
} from '@mui/icons-material';


import { database, storage, firebase } from 'config/firebase';

import { IScore } from 'types/project.type';

import GoBack from 'components/GoBack';
import { isAllowed } from 'utils/permission';
import { useUserData } from 'hooks/useUserData';
import { styleModal } from 'components/Modal/style';
import { FilePond } from 'react-filepond';
import readFile from 'utils/storage';

const ScoresDetails = () => {

  const { role } = useUserData()
  const { projectId } = useParams()

  const [scores, setScores] = useState<IScore[] | null>(null)
  const [expanded, setExpanded] = useState<string | false>(false);
  const [instrument, setInstrument] = useState<any>(null);

  const [openModalNewScores, setOpenModalNewScores] = useState(false)

  const [files, setFiles] = useState([]);
  const ref = useRef(null);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function getScores() {
    const { docs } = await database
      .collection('scores')
      .where('project', '==', projectId)
      .get()

    const data = docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data()
      }
    }) as IScore[]

    setScores(data.sort((a, b) => a.order - b.order))
  }

  function openScore(url: string) {
    window.open(url, '_blank')
  }

  async function handleAddScore(data: any) {
    setInstrument(data)
    setOpenModalNewScores(true)
  }

  async function handleAddInstrument() {

  }

  function closeModal() {
    setOpenModalNewScores(false)
    setInstrument(null)
  }

  async function handleScore(metadata: firebase.storage.FullMetadata) {
    const pathDownload = await readFile(`/${metadata.fullPath}`)
    const [selectedInstrument]: any = scores && scores.filter(score => score.instrument === instrument)

    const data = {
      sheets: [{
        name: metadata.name.split('.')[0],
        url: pathDownload, 
      },...selectedInstrument.sheets],
    }

    await database
      .collection('scores')
      .doc(selectedInstrument.id)
      .update(data)

    getScores()
    setOpenModalNewScores(false)
  }

  useEffect(() => {
    getScores()
  }, [])

  if (!scores) {
    return <CircularProgress color="primary" />
  }

  if (scores && scores.length === 0) {
    return (
      <>
        <GoBack />
        <Typography variant="h5">Não temos partituras disponíveis no momento</Typography>
      </>
    )
  }

  return (
    <div>
      <Modal
        open={openModalNewScores}
        onClose={closeModal}
      >
        <Box sx={{ ...styleModal, width: '30vw' }}>
          <Typography variant="h6" component="h2" mb={3}>
            Adicione uma nova partitura
          </Typography>

          <FilePond
            files={files}
            ref={ref}
            allowMultiple={false}
            name="files"
            labelIdle="Arraste uma partitura ou clique para selecionar!"
            acceptedFileTypes={["pdf"]}
            server={{
              process: (
                _fieldName,
                file,
                _metadata,
                load,
                error,
                progress,
                _abort
              ) => {
                const score = storage.ref().child(`scores/${projectId}/${instrument}/${file.name}`).put(file, {
                  contentType: file.type
                });
                score.on(
                  firebase.storage.TaskState.RUNNING,
                  (snap: any) => {
                    progress(true, snap.bytesTransferred, snap.totalBytes);
                  },
                  (err: any) => {
                    error(err.message);
                  },
                  () => {
                    handleScore(score.snapshot.metadata)
                    load(file.name)
                  }
                );
                score.on(
                  firebase.storage.TaskState.SUCCESS,
                  (snap: any) => {
                    
                  },
                  (err: any) => {
                    error(err.message);
                  },
                  () => {
                    handleScore(score.snapshot.metadata)
                    load(file.name)
                  }
                );
              },
            }}
            onupdatefiles={fileItems => {
              setFiles(fileItems.map(fileItem => fileItem.file) as any)
            }}
          />
        </Box>
      </Modal>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <GoBack />
        {
          isAllowed(role as string, 'add instrument') && (
            <Button
              onClick={handleAddInstrument}
              variant="outlined"
            >
              <Add />
              Adicionar um novo instrumento
            </Button>
          )
        }
      </Box>
      {
        scores.map(currentInstrument => (
          <Accordion key={currentInstrument.id} expanded={expanded === currentInstrument.id} onChange={handleChange(currentInstrument.id)}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
            >
              <FolderOpen sx={{ mr: 1 }} />
              <Typography>{currentInstrument.instrument}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {
                isAllowed(role as string, 'add file') && (
                  <Button
                    onClick={() => handleAddScore(currentInstrument.instrument)}
                    variant="outlined"
                  >
                    <Add />
                    Adicionar uma nova partitura
                  </Button>
                )
              }
              <Box
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper'
                }}
              >
                <List>
                  {
                    currentInstrument.sheets.length >= 1
                      ? currentInstrument.sheets.map(({ name, url }, index) => (
                        <div key={index}>
                          <ListItem disablePadding>
                            <ListItemButton>
                              <ListItemIcon>
                                <PictureAsPdf color="error" />
                              </ListItemIcon>
                              <ListItemText primary={name} />
                              <ListItemIcon onClick={() => openScore(url)}>
                                <OpenInNew color="primary" />
                              </ListItemIcon>
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                        </div>
                      ))
                      : <Typography variant="subtitle1">Não temos partituras disponíveis para {instrument}</Typography>
                  }
                </List>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))
      }
    </div >
  )
}

export default ScoresDetails