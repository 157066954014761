const permissions: any = {
  'ADMIN': [
    'add rehearsal',
    'delete rehearsal',
    'edit rehearsal',
    'add instrument',
    'add file',
    'remove file'
  ]
}

export function isAllowed(userRole: string, action: string) {
  return permissions[userRole] && permissions[userRole].includes(action)
}