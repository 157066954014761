import React from 'react'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'

import 'react-toastify/dist/ReactToastify.css'
import "filepond/dist/filepond.min.css";
import 'moment/locale/pt-br'


import { theme } from 'styles/theme'
import Routes from './routes'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes />
      <ToastContainer />
    </ThemeProvider>
  )
}

export default App
